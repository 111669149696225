<template>
  <v-col class="mt-2" >
    <v-col class="shades white pa-6">
      <v-row  class="px-4">
        <v-col cols="12" md="12">
          <h3>매장의 상세정보를 입력해주세요.</h3>
        </v-col>
        <v-col cols="12" md="10">
          <div class="label mt-6 mb-2">매장을 한 줄로 소개해주세요.</div>
          <v-text-field v-model="form.abstraction" name="abstraction" type="text"
                        :rules="[errors.first('abstraction') || !errors.has('abstraction')]"
                        placeholder="매장 한 줄 소개"
                        @input="checkDone"
                        single-line outlined></v-text-field>
        </v-col>
        <v-col cols="12" md="10">
          <div class="label mb-2">사장님의 매장을 소개해주세요.</div>
          <v-textarea v-model="form.introduction" name="introduction" type="text"
                        :rules="[errors.first('introduction') || !errors.has('introduction')]"
                        placeholder="매장 상세 소개"
                        @input="checkDone"
                        outlined></v-textarea>
        </v-col>
<!--        <v-col cols="12" md="10" v-if="camData.type === 'P'">-->
<!--          <div class="label mt-6 mb-2">리뷰 가이드 파일의 링크를 입력해주세요.</div>-->
<!--          <v-text-field v-model="form.guide" name="guide" type="text"-->
<!--                        v-validate="'min:2'"-->
<!--                        :rules="[errors.first('guide') || !errors.has('guide')]"-->
<!--                        hint="슈퍼차트 회원이 리뷰 작성시 필요한 이미지등이 포함되어있는 구글 문서의 링크를 입력해주세요."-->
<!--                        persistent-hint-->
<!--                        @input="checkDone"-->
<!--                        single-line outlined></v-text-field>-->
<!--        </v-col>-->
      </v-row>
    </v-col>
    <v-col class="shades white pa-6 mt-2" v-if="camData.type === 'T'">
      <v-row  class="px-4">
<!--        <v-col cols="12" md="10">-->
<!--          <div class="label mb-2">슈퍼차트 회원이 방문할 때, 사전 예약이 필요하신가요?</div>-->
<!--          <v-btn-toggle v-model="form.isReservationRequired" @change="checkDone" mandatory>-->
<!--            <v-btn :value=false active-class="rouge&#45;&#45;text text&#45;&#45;rouge v-btn&#45;&#45;active" class="ma-0" style="width: 130px" outlined disabled>아니오</v-btn>-->
<!--            <v-btn :value=true active-class="rouge&#45;&#45;text text&#45;&#45;rouge v-btn&#45;&#45;active" class="ma-0" style="width: 130px" outlined disabled>예</v-btn>-->
<!--          </v-btn-toggle>-->
<!--        </v-col>-->
<!--        <v-col class="my-0" v-if="form.isReservationRequired" cols="12" md="12" >-->
<!--          <span class="caption rouge&#45;&#45;text">예약 없이 즉시 방문이 가능한 경우 회원의 방문율이 더 높아질 수 있습니다.</span>-->
<!--        </v-col>-->
        <v-col cols="12" md="9" v-if="camData.type === 'T'">
          <span class="label mb-2">혜택 제공 방식을 선택해주세요.</span>
          <v-menu v-model="menuDeliveryMethod" :close-on-content-click="false" :max-width="400" offset-x>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small color="#4770BD" v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
            </template>
            <v-card>
              <v-card-title>
                <div>
                  <v-icon color="default" @click="menuDeliveryMethod=false">close</v-icon><br>
                  <h2 class="mt-2 font-weight-bold subheading">혜택 제공 방식 안내</h2>
                </div>
              </v-card-title>
              <v-card-text>
                <div class="body-2 font-weight-bold">무료 제공</div>
                <div>회원이 매장에 방문하여 제공 혜택을 무료로 이용합니다.</div>
                <div class="mt-4 body-2 font-weight-bold">구매 지원금 지급</div>
                <div>회원이 매장을 방문하기 전 네이버 예약등을 통해 온라인에서 직접 결제해야 하는 경우 선택해주세요. 제안을 보낼 때 제안금액과 구매 지원금을 합한 금액이 결제됩니다.</div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="rouge white-two--text" tile @click="menuDeliveryMethod = false">
                  닫기
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
          <v-radio-group v-model="form.deliveryMethod" row :mandatory="true" class="radio_group" @change="checkDone"
                         :hint="form.deliveryMethod == 'F' ? '회원이 매장에 방문하여 제공 혜택을 무료로 이용합니다.' : '매장 방문 전 네이버 예약등을 통해 결제를 해야하는 경우 선택해주세요. 제안을 보낼 때 제안금액과 구매 지원금을 합한 금액이 결제됩니다.'"
                         persistent-hint>
            <v-radio label="무료 제공" value="F" class="radio pl-2" color="rouge"></v-radio>
            <v-radio label="구매 지원금 지급" value="PR" class="radio pl-2" color="rouge"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" class="my-2" v-if="form.deliveryMethod == 'PR' && camData.type === 'T'">
          <div class="label mt-6 mb-2">회원이 매장 방문을 예약하는 페이지의 주소를 입력해주세요.</div>
          <v-text-field v-model="form.storeUrl" name="storeUrl" type="text"
                        v-validate="'min:2'"
                        :rules="[errors.first('storeUrl') || !errors.has('storeUrl')]"
                        placeholder="https//"
                        persistent-hint
                        @input="checkDone"
                        single-line outlined hide-details></v-text-field>
        </v-col>
        <v-col cols="12" class="my-2" v-if="form.deliveryMethod == 'PR' && camData.type === 'T'">
          <div class="label mt-6 mb-2">예약 플랫폼(네이버 예약 등)을 통해 매장 이용시 필수 요구사항을 정해주세요.</div>
          <v-row>
            <v-checkbox
                v-model="form.requirement"
                @change="checkDone"
                label="텍스트 리뷰" color="rouge" value="text" hide-details
                class="checkbox pl-2 pt-2 mr-2"></v-checkbox>
            <v-checkbox
                v-model="form.requirement"
                @change="checkDone"
                label="포토 리뷰" color="rouge" value="photo" hide-details
                class="checkbox pl-2 pt-2 mr-2"></v-checkbox>
            <v-checkbox
                v-model="form.requirement"
                @change="checkDone"
                label="네이버 영수증 리뷰(포토)" color="rouge" value="receipt" hide-details
                class="checkbox pl-2 pt-2 mr-2"></v-checkbox>
            <v-checkbox
                v-model="form.requirement"
                @change="checkDone"
                label="네이버 지도에서 저장하기" color="rouge" value="naverMap" hide-details
                class="checkbox pl-2 pt-2 mr-2"></v-checkbox>
          </v-row>
        </v-col>
        <v-col class="mt-6" cols="12" md="10" v-if="form.deliveryMethod == 'PR' && camData.type === 'T'">
          <div class="label mb-2">예약/방문자 리뷰에 공정위 문구 필수 삽입 여부를 선택해주세요.</div>
          <v-btn-toggle v-model="form.ftcRequired" @change="checkDone" mandatory>
            <v-btn :value=true active-class="rouge--text text--rouge v-btn--active" class="ma-0" style="width: 130px" outlined>예</v-btn>
            <v-btn :value=false active-class="rouge--text text--rouge v-btn--active" class="ma-0" style="width: 130px" outlined>아니오</v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col cols="12" class="my-2" v-if="form.deliveryMethod == 'PR' && form.ftcRequired && camData.type === 'T'">
          <div class="label mt-6 mb-2">예약/방문자 리뷰에 들어가는 공정위 문구를 입력해주세요.</div>
          <v-text-field v-model="form.ftcText" name="ftcText" type="text"
                        v-validate="'min:2'"
                        :rules="[errors.first('ftcText') || !errors.has('ftcText')]"
                        placeholder="제품을 무상으로 지원받아 작성한 솔직한 후기입니다."
                        persistent-hint
                        @input="checkDone"
                        single-line outlined hide-details></v-text-field>
        </v-col>
        <v-col cols="12" class="mt-6" v-if="form.deliveryMethod == 'PR' && camData.type === 'T'">
          <div class="label">슈퍼차트 회원에게 입력받아야 하는 정보를 선택해주세요.</div>
          <v-row>
            <v-col class="mt-2" cols="12" md="6">
              <v-row>
                <v-checkbox
                    v-model="form.delivery"
                    @change="checkDone"
                    label="실명" color="rouge" value="name" hide-details
                    class="checkbox pl-2 pt-2 mr-2"></v-checkbox>
                <v-checkbox
                    v-model="form.delivery"
                    @change="checkDone"
                    label="연락처" color="rouge" value="contact" hide-details
                    class="checkbox pl-2 pt-2 mr-2"></v-checkbox>
                <v-checkbox
                    v-model="form.delivery"
                    @change="checkDone"
                    label="이메일" color="rouge" value="email" hide-details
                    class="checkbox pl-2 pt-2"></v-checkbox>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <div class="line my-6"></div>
        <v-col cols="12" md="10">
          <div class="label mt-6 mb-2">회원 방문시 필요한 매장 예약 관련 정보를 입력해주세요.</div>
          <v-text-field v-model="form.reservationComment" name="reservationComment" type="text"
                        v-validate="'min:2'"
                        :rules="[errors.first('reservationComment') || !errors.has('reservationComment')]"
                        :hint="form.isReservationRequired ? '예) 슈퍼차트 회원으로 최소 방문 하루 전 예약해주세요.' : '예)예약 없이 방문이 가능하나 웨이팅이 있을 수도 있습니다.'"
                        persistent-hint
                        @input="checkDone"
                        single-line outlined></v-text-field>
        </v-col>
        <v-col class="mt-2" cols="12" md="10" >
          <div class="label mb-2">회원이 이용할 수 있는 요일이나 시간을 제한하고 싶으신가요?</div>
          <v-btn-toggle v-model="limitation" @change="checkDone" mandatory>
            <v-btn :value=false active-class="rouge--text text--rouge v-btn--active" class="ma-0" style="width: 130px" outlined>아니오</v-btn>
            <v-btn :value=true active-class="rouge--text text--rouge v-btn--active" class="ma-0" style="width: 130px" outlined>예</v-btn>
          </v-btn-toggle>
        </v-col>
<!--        <v-col class="my-0" v-if="limitation" cols="12" md="12" >-->
<!--          <span class="caption rouge&#45;&#45;text">예약 없이 즉시 방문이 가능한 경우 회원의 방문율이 더 높아질 수 있습니다.</span>-->
<!--        </v-col>-->
        <v-col v-if="limitation" cols="12" md="10">
          <div class="label mt-6 mb-2">이용이 불가한 요일과 시간을 알려주세요.</div>
          <v-text-field v-model="form.usingLimitation" name="usingLimitation" type="text"
                        :rules="[errors.first('usingLimitation') || !errors.has('usingLimitation')]"
                        hint="예1) 금, 토 방문 불가, 예2) 12:00~13:30 시간대 방문 불가"
                        persistent-hint
                        @change="checkDone"
                        single-line outlined></v-text-field>
        </v-col>
        <v-col class="mt-2" cols="12" md="10" >
          <div class="label my-2">추가로 받아야 하는 정보가 있으실 경우 질문 형식으로 입력해주세요.</div>
          <v-row v-for="(m, index) in (form.additionalInfo || [])" :key="index">
            <v-text-field v-model="form.additionalInfo[index]" name="additionalInfo" type="text"
                          class="mt-2"
                          single-line outlined solo flat hide-details></v-text-field>
            <v-btn color="rouge" @click="removeAdditionalInfo(index)" icon text class="plus_btn"><v-icon style="color:#757575">mdi-trash-can-outline</v-icon></v-btn>
          </v-row>
          <v-row>
            <v-text-field v-model="additionalInfo" name="mission" type="text"
                          class="mt-2"
                          @keyup.enter="addAdditionalInfo"
                          single-line outlined solo flat hide-details></v-text-field>
            <v-btn color="rouge" @click="addAdditionalInfo" tile class="plus_btn">추가</v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-col>
</template>
<script>
  import {EventBus} from "@/components/shared/event-bus";

  export default {
    name: 'Page3',
    props: ['camData', 'option'],
    data: function () {
      return {
        form: {
          abstraction: this.camData.abstraction || '',
          introduction: this.camData.introduction || '',
          isReservationRequired: true,
          reservationComment: this.camData.reservationComment || undefined,
          guide: this.camData.guide || '',
          additionalInfo: this.camData.additionalInfo || [],
          delivery: this.camData.delivery || [],
          deliveryMethod: this.camData.deliveryMethod || 'F',
          storeUrl: this.camData.storeUrl || '',
          requirement: this.camData.requirement || [],
          ftcRequired: this.camData.ftcRequired || true,
          ftcText: this.camData.ftcText || '제품을 무상으로 지원받아 작성한 솔직한 후기입니다.',
          step: 3
        },
        limitation: false,
        menuDeliveryMethod: false
      }
    },
    watch: {
      'form.requirement': function(value) {
        if (value.length > 0 && (value[value.length - 1] == 'text' || value[value.length - 1] == 'photo' || value[value.length - 1] == 'video' || value[value.length - 1] == 'receipt')) {
          this._.remove(this.form.requirement, (item) => {
            return (['text', 'photo', 'video', 'receipt'].indexOf(item) > -1 && item !== value[value.length - 1]);
          });
        }
      }
    },
    methods: {
      addAdditionalInfo: function () {
        this.form.additionalInfo = this.form.additionalInfo || [];
        if (this.additionalInfo.length > 0 && this.form.additionalInfo.indexOf(this.additionalInfo) === -1) {
          this.form.additionalInfo.push(this.additionalInfo);
          this.additionalInfo = '';
          this.checkDone();
        }
      },
      removeAdditionalInfo: function (idx) {
        console.log('remove' + idx);
        this.form.additionalInfo.splice(idx, 1);
        this.checkDone();
      },
      checkDone() {
        this.$validator.validateAll().then(success => {
          if (success) {
            this.form.step = 3;
            this.$emit('updateEvent', this.form);
          }
        });
      }
    },
    created() {
      // this.checkDone();
      EventBus.$on("update:campaign", async () => {
        this._.merge(this.form, this._.pick(this.camData, ['abstraction', 'introduction']));
      });
    }
  }
</script>
<style scoped lang="scss">
  @import '../../../../assets/scss/custom';

  .checkbox {
    border: 1px solid;
    border-radius: 8px;
    max-width: 230px;
    height: 40px;
    margin-top: 0px;
    flex: 1 1 auto;
  }

  .radio_group {
    margin-top: 0px;
  }

  .radio {
    border: 1px solid;
    border-radius: 8px;
    max-width: 130px;
    height: 40px;
    margin-top: 0px;
    width: 130px;
  }

  .radio.v-item--active {
    border-color: var(--rouge-color);
  }

  .plus_btn {
    width: 56px;
    height:40px !important;
    min-width: 40px !important;
    color: white;
    padding: 0 !important;
  }
</style>